import styled from 'styled-components';

export const ContainerDetailDog = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  background: #fff9e7;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const ButtonDetailDog = styled.button`
  position: absolute;
  top: 50px;
  left: 50px;
  width: 120px;
  height: 60px;
  border: none;
  background-color: #FFFFFF;
  border-radius: 14px;
  cursor: pointer;
  & img {
    width: 40px;
    height: 40px;
  }
`

export const DescriptionDetailDog = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  gap: 10px;
  & h1 {
    color: #0A1820;
    font-size: 4rem;
    font-weight: 800;
    margin: 0;
  }
  & h2 {
    color: #0A1820;
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
  }
  & h3 {
    color: #0A1820;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
  }
  & h4 {
    color: #0A1820;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
`

export const ImageDetailDog = styled.div`
  width: 600px;
  height: 600px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  & img {
    width: 500px;
    height: 500px;
  }
`
