import styled from 'styled-components';

export const ContainerNotFound = styled.div`
  background: #0080a9;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
`;

export const H1NotFound = styled.h1`
  color: #ffffff;
  margin: 0;
`;

export const H2NotFound = styled.h2`
  color: #ffffff;
  margin: 0;
`;
