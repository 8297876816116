import styled from "styled-components";

export const ContainerFilters = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100px;
`

export const H1Filters = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 800;
  color: #0A1820;
`

export const SelectFilters = styled.select`
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 30px;
  background: #F4F4F4;
  outline: none;
`

export const OptionFilters = styled.option`
  text-align: center;
`