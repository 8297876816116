import styled from 'styled-components';

export const ContainerLandingPage = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100vh;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonLandingPage = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 750px;
  left: 80px;
  border: none;
  border-radius: 30px;
  background: #f85287;
  color: #f4f4f4;
  cursor: pointer;
  height: 50px;
  width: 300px;
  & span {
    font-weight: bold;
    width: 160px;
  }
  & img {
    width: 20px;
    height: 20px;
  }
`;

export const H1LandingPage = styled.h1`
  position: absolute;
  top: 450px;
  left: 80px;
  color: #0A1820;
  font-weight: 900;
  font-size: 5rem;
`
export const H2LandingPage = styled.h2`
  position: absolute;
  top: 600px;
  left: 80px;
  color: #f4f4f4;
  font-weight: 400;
`