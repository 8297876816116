import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ContainerSearchBar,
  LogoSearchBar,
  FormSearchBar,
  ItemsSearchBar,
} from './SearchBarStyles';
import Logo from '../../assets/paw-yellow.svg';
import Searching from '../../assets/search-solid.svg';
import Create from '../../assets/create-solid-rose.svg';
import Reload from '../../assets/reload-solid-rose.svg';
import { Link } from 'react-router-dom';
import { getNamedog } from '../../redux/actions/actions';

export const SearchBar = () => {
  const [dogValue, setDogValue] = useState('');

  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    setDogValue(target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (dogValue.trim().length <= 0) return;
    dispatch(getNamedog(dogValue));
    setDogValue('');
  };

  const handleReload = () => {
    window.location.reload(false);
  };

  return (
    <ContainerSearchBar className="ContainerSearchBar" onSubmit={handleSubmit}>
      <Link to="/home">
        <LogoSearchBar>
          <img src={Logo} alt={Logo} />
        </LogoSearchBar>
      </Link>

      <FormSearchBar className="SearchingSearchBar">
        <input type="text" placeholder="Search dog" value={dogValue} onChange={handleChange} />
        <button type="submit" onClick={handleSubmit}>
          <img src={Searching} alt={Searching} />
        </button>
      </FormSearchBar>

      <ItemsSearchBar>

        <Link to="/create">
          <button>
            <img src={Create} alt={Create} />
          </button>
        </Link>

        <button onClick={handleReload}>
          <img src={Reload} alt={Reload} />
        </button>
      </ItemsSearchBar>
    </ContainerSearchBar>
  );
};
