import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SearchBar } from '../../components/SearchBar/SearchBar';
import { Filters } from '../../components/Filters/Filters';
import { Paginated } from '../../components/Paginated/Paginated';
import { getAllDogs, getAllTemperaments } from '../../redux/actions/actions';
import { ContainerHomePage } from './HomePageStyles';

export const HomePage = () => {
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getAllDogs());
    dispatch(getAllTemperaments());
  }, [dispatch]);

  return (
    <ContainerHomePage className="ContainerHomePage">
      <SearchBar />
      <Filters />
      <Paginated/>
    </ContainerHomePage>
  );
};
