import { Card } from '../../components/Card/Card';
import { ContainerCards } from './CardsStyles';

export const Cards = ({ items }) => {

  return (
    <ContainerCards className="ContainerCards">
      {items.length > 0 ? (
        items &&
        items.map((dog) => {
          return (
            <Card
              key={dog.id}
              id={dog.id}
              name={dog.name}
              breed={dog.breed}
              height_min={dog.height_min}
              height_max={dog.height_max}
              weight_min={dog.weight_min}
              weight_max={dog.weight_max}
              life_span={dog.life_span}
              image={dog.image}
              temperaments={dog.temperaments}
            />
          );
        })
      ) : (
        <h1>No dogs found</h1>
      )}
    </ContainerCards>
  );
};
