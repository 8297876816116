import { Route, Routes } from 'react-router-dom';

import './App.css';
import { NotFound404 } from './pages/404/NotFound404';
import { CreateDog } from './pages/createDog/CreateDog';
import { DetailDog } from './pages/detailDog/DetailDog';
import { HomePage } from './pages/home/HomePage';
import { LandingPage } from './pages/landingPage/LandingPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path='/home/:id' element={<DetailDog/>}/>
        <Route path='/create' element={<CreateDog/>}/>
        <Route path='/*' element={<NotFound404/>}/>
      </Routes>
    </div>
  );
}

export default App;
