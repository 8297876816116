import {
  BackCreateDog,
  ButtonCreateDog,
  ContainerCreateDog,
  ContainersInputs,
  FormCreateDog,
  H1CreateDog,
  ImageCreateDog,
  MessageCreateDog,
  TemperamentsCreateDog,
} from './CreateDogStyles';

import Tash from '../../assets/empty-tash.svg';
import Back from '../../assets/back-arrow.svg';
import { useCreateDog } from './Hooks/useCreateDog';

export const CreateDog = () => {
  const {
    backToHome,
    handleSubmit,
    values,
    handleInputChange,
    errors,
    handleSelect,
    temperaments,
    handleDeleteTemperament,
  } = useCreateDog();

  return (
    <ContainerCreateDog className="ContainerCreateDog">
      <BackCreateDog onClick={backToHome}>
        <img src={Back} alt={Back} />
      </BackCreateDog>

      <H1CreateDog>Create Dog</H1CreateDog>
      <FormCreateDog onSubmit={handleSubmit} className="FormCreateDog">
        <ContainersInputs>
          <label htmlFor="Name">Name Dog</label>
          <input
            type="text"
            placeholder="Enter a name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
          />
          {errors.name && <p>{errors.name}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="Breed">Breed</label>
          <input
            type="text"
            placeholder="Enter a breed"
            name="breed"
            value={values.breed}
            onChange={handleInputChange}
          />
          {errors.breed && <p>{errors.breed}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="HeightMin">Height Min</label>
          <input
            type="number"
            min="0"
            max="20"
            placeholder="Enter a minimum height"
            name="height_min"
            value={values.height_min}
            onChange={handleInputChange}
          />
          {errors.height_min && <p>{errors.height_min}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="HeightMax">Height Max</label>
          <input
            type="number"
            min="0"
            max="20"
            placeholder="Enter a maximum height"
            name="height_max"
            value={values.height_max}
            onChange={handleInputChange}
          />
          {errors.height_max && <p>{errors.height_max}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="Weightmin">Weight Min</label>
          <input
            type="number"
            min="0"
            max="20"
            placeholder="Enter a minimum weight"
            name="weight_min"
            value={values.weight_min}
            onChange={handleInputChange}
          />
          {errors.weight_min && <p>{errors.weight_min}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="Weightmax">Weight Max</label>
          <input
            type="number"
            min="0"
            max="20"
            placeholder="Enter a maximum weight"
            name="weight_max"
            value={values.weight_max}
            onChange={handleInputChange}
          />
          {errors.weight_max && <p>{errors.weight_max}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="Lifespan">Life Span</label>
          <input
            type="text"
            placeholder="Enter life expectancy - Example: 1 - 2 years"
            name="life_span"
            value={values.life_span}
            onChange={handleInputChange}
          />
          {errors.life_span && <p>{errors.life_span}</p>}
        </ContainersInputs>

        <ContainersInputs>
          <label htmlFor="Image">Image URL</label>
          <input
            type="text"
            placeholder="Enter the URL of the image"
            name="image"
            value={values.image}
            onChange={handleInputChange}
          />
          {errors.image && <p>{errors.image}</p>}
        </ContainersInputs>

        <ImageCreateDog>
          <h2>Preview image</h2>
          <img
            src={values.image.length === 0 ? `https://acortar.link/Xla7jl` : values.image}
            alt={values.image}
          />
        </ImageCreateDog>

        <TemperamentsCreateDog>
          <select
            onChange={handleSelect}
            name="temperaments"
            disabled={values.temperaments.length >= 6}
            defaultValue="title"
          >
            <option value="title" disabled name="temperaments">
              temperaments
            </option>
            {temperaments.map((temperament) => {
              return (
                <option value={temperament.name} key={temperament.id}>
                  {temperament.name}
                </option>
              );
            })}
          </select>

          <ul>
            {values.temperaments.map((temperament) => {
              return (
                <li key={temperament}>
                  {temperament}
                  <button onClick={() => handleDeleteTemperament(temperament)}>
                    <img src={Tash} alt={Tash} />
                  </button>
                </li>
              );
            })}
          </ul>
          {errors.temperaments && <p>{errors.temperaments}</p>}
        </TemperamentsCreateDog>

        <div>
          {errors &&
          (errors.name ||
            errors.breed ||
            errors.height_min ||
            errors.height_max ||
            errors.weight_min ||
            errors.weight_max ||
            errors.life_span ||
            !values.name.length === 0 ||
            values.breed.length === 0 ||
            values.life_span.length === 0 ||
            values.temperaments.length === 0 ||
            values.height_min <= 0 ||
            values.height_max <= 0 ||
            values.weight_min <= 0 ||
            values.weight_max <= 0) ? (
            <MessageCreateDog>The dog cannot be created</MessageCreateDog>
          ) : (
            <ButtonCreateDog type="submit">CREATE</ButtonCreateDog>
          )}
        </div>
      </FormCreateDog>
    </ContainerCreateDog>
  );
};
