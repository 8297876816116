import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllTemperaments, postDog } from '../../../redux/actions/actions';
import { Validation } from '../ValidationCreateDog';

export const useCreateDog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { temperaments } = useSelector((state) => state); // Array de los temperamentos

  const [values, setValues] = useState({
    name: '',
    breed: '',
    height_min: 0,
    height_max: 0,
    weight_min: 0,
    weight_max: 0,
    life_span: '',
    temperaments: [],
    image: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    breed: '',
    height_min: '',
    height_max: '',
    weight_min: '',
    weight_max: '',
    life_span: '',
    temperaments: [],
    image: '',
  });

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });

    setErrors(
      Validation({
        ...values,
        [target.name]: target.value,
      }),
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (values.image.length === 0) {
      values.image = `https://acortar.link/Xla7jl`;
    }
    dispatch(postDog(values));
    setValues({
      name: '',
      breed: '',
      height_min: '',
      height_max: '',
      weight_min: '',
      weight_max: '',
      life_span: '',
      temperaments: [],
      image: '',
    });
    navigate('/home');
  };

  const handleSelect = ({ target }) => {
    if (!values.temperaments.includes(target.value)) {
      setValues({
        ...values,
        [target.name]: [...values.temperaments, target.value],
      });
    }

    setErrors(
      Validation({
        ...values,
        [target.name]: target.value,
      }),
    );
  };

  const handleDeleteTemperament = (temperament) => {
    setValues({
      ...values,
      temperaments: values.temperaments.filter((t) => t !== temperament),
    });
  };

  const backToHome = () => {
    navigate('/home');
  };

  useEffect(() => {
    dispatch(getAllTemperaments());
  }, [dispatch]);

  return {
    backToHome,
    handleSubmit,
    values,
    handleInputChange,
    errors,
    handleSelect,
    temperaments,
    handleDeleteTemperament,
  };
};
