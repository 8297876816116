import { ButtonLandingPage, ContainerLandingPage, H1LandingPage, H2LandingPage } from './LandingPageStyles';
import Banner from '../../assets/LandingPage.png';
import Paw from '../../assets/paw-solid.svg';
import { Link } from 'react-router-dom';

export const LandingPage = () => {
  return (
    <ContainerLandingPage className="LandingPage">
      <img src={Banner} alt={Banner} />
      <H1LandingPage>Find your pet</H1LandingPage>
      <H2LandingPage>Looking for a loving pet for a loving home</H2LandingPage>
      <Link to='/home'>
        <ButtonLandingPage>
          <span>Find you Pet start!</span> <img src={Paw} alt={Paw} />
        </ButtonLandingPage>
      </Link>
    </ContainerLandingPage>
  );
};
