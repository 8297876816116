import axios from 'axios';

import {
  CLEAR_DOG_DETAIL,
  CREATE_DOG,
  FILTER_BY_NAME,
  FILTER_BY_TEMPERAMENTS,
  FILTER_BY_WEIGHT,
  FILTER_CREATE_DOG,
  GET_ALL_DOGS,
  GET_ALL_TEMPERAMENTS,
  GET_DOG_ID,
  GET_NAME_DOG,
} from './types';

export const getAllDogs = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`/dogs`);
      return dispatch({
        type: GET_ALL_DOGS,
        payload: data.dogAll,
      });
    } catch (error) {
      console.log(new Error(error));
    }
  };
};

export const getAllTemperaments = () => {
  return async (dispatch) => {
    let { data } = await axios.get(`/temperaments`);
    return dispatch({
      type: GET_ALL_TEMPERAMENTS,
      payload: data.temperamentDB,
    });
  };
};

export const getNamedog = (nameDog) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`/dogs?name=${nameDog}`);
      return dispatch({
        type: GET_NAME_DOG,
        payload: data,
      });
    } catch (error) {
      alert('Dog not found');
    }
  };
};

export const filterByName = (payload) => {
  return {
    type: FILTER_BY_NAME,
    payload,
  };
};

export const filterByWeight = (payload) => {
  return {
    type: FILTER_BY_WEIGHT,
    payload,
  };
};

export const filterCreatedDog = (payload) => {
  return {
    type: FILTER_CREATE_DOG,
    payload,
  };
};

export const filterTemperament = (payload) => {
  return {
    type: FILTER_BY_TEMPERAMENTS,
    payload,
  };
};

export const getDogId = (id) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`/dogs/${id}`);
      return dispatch({
        type: GET_DOG_ID,
        payload: data.exists,
      });
    } catch (error) {
      console.log(new Error(error));
    }
  };
};

export const clearDogDetail = () => {
  return {
    type: CLEAR_DOG_DETAIL,
  };
};

export const postDog = (data) => {
  return async (dispatch) => {
    try {
      const posted = await axios.post('/dogs', data);
      if (posted) {
        alert('Dog created successfully');
        return dispatch({
          type: CREATE_DOG,
          payload: posted,
        });
      }
    } catch (error) {
      alert('Could not create dog');
    }
  };
};
