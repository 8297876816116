import { ContainerNotFound, H1NotFound, H2NotFound } from "./NotFound404Styles"

export const NotFound404 = () => {
  return (
    <ContainerNotFound>
      <H1NotFound>Hmmm!</H1NotFound>
      <H2NotFound>We didn't find what you were looking for.</H2NotFound>
    </ContainerNotFound>
  )
}
