// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterByName, filterByWeight, filterCreatedDog, filterTemperament } from '../../redux/actions/actions';

import { ContainerFilters, H1Filters, OptionFilters, SelectFilters } from './FiltersStyles';

export const Filters = () => {
  const dispatch = useDispatch();
  const {temperaments} = useSelector(state => state);

  // const [order, setOrder] = useState('');
  

  const handleFilterName = ({ target }) => {
    dispatch(filterByName(target.value));
    // setOrder(`Order ${target.value}`);
  }

  const handleFilterWeight = ({ target }) => {
    dispatch(filterByWeight(target.value))
    // setOrder(`Order ${target.value}`)
  }

  const handleFilterCreated = ({ target }) => {
    dispatch(filterCreatedDog(target.value));
  }

  const handleFilterTemperament = (event) => {
    event.preventDefault();
    dispatch(filterTemperament(event.target.value))
  }

  return (
    <ContainerFilters>
      <H1Filters>Dogs Filter</H1Filters>

      <SelectFilters className="Order by name" onChange={handleFilterName}>
        <OptionFilters defaultValue="orderbyname">Order by name</OptionFilters>
        <OptionFilters key={1} value="A-Z">A - Z</OptionFilters>
        <OptionFilters key={2} value="Z-A">Z - A</OptionFilters>
      </SelectFilters>

      <SelectFilters className="Order by wieght" onChange={handleFilterWeight}>
        <OptionFilters defaultValue="orderbywieght">Order by wieght</OptionFilters>
        <OptionFilters key={1} value='max_weight'>Max</OptionFilters>
        <OptionFilters key={2} value='min_weight'>Min</OptionFilters>
      </SelectFilters>

      <SelectFilters className="Order by created" onChange={handleFilterCreated}>
        <OptionFilters defaultValue="orderbycreated">Order by created</OptionFilters>
        <OptionFilters key={1} value='all'>All</OptionFilters>
        <OptionFilters key={2} value='DB'>DB</OptionFilters>
        <OptionFilters key={3} value='API'>API</OptionFilters>
      </SelectFilters>

      <SelectFilters className="Order by temperaments" onChange={handleFilterTemperament}>
        <OptionFilters defaultValue="orderbytemperaments">Order by temperaments</OptionFilters>
        <OptionFilters key={1 + 'T'} value='All'>All</OptionFilters>
        {
          temperaments.map( temperament => (
            <OptionFilters key={temperament.id} value={temperament.name}>{temperament.name}</OptionFilters>
          ))
        }
      </SelectFilters>
    </ContainerFilters>
  );
};
