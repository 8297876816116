import styled from "styled-components";

export const ContainerCreateDog = styled.div`
  background: #0080A9;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  place-items: center;
`

export const H1CreateDog = styled.h1`
  color: #FFFFFF;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
`

export const FormCreateDog = styled.form`
  display: grid;
  width: 800px;
  gap: 10px;
`

export const ContainersInputs = styled.div`
  display: grid;
  gap: 10px;
  & label {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 400;
  }
  & input {
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 20px;
    padding: 10px;
    outline: none;
  }
  & p {
    color: #F6C101;
    margin: 0;
  }
`

export const ImageCreateDog = styled.div`
  width: 250px;
  height: 250px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 300px;
  right: 300px;
  border-radius: 10px;
  & h2 {
    position: absolute;
    top: -70px;
    color: #FFFFFF;
  }
  & img {
    width: 200px;
    height: 200px;
  }
`

export const TemperamentsCreateDog = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-top: 50px;
  & select {
    width: 300px;
    height: 40px;
    border: none;
    border-radius: 10px;
  }
  & ul {
    list-style: none;
    width: 500px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
  }
  & li {
    color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  & button {
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
  & img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`

export const ButtonCreateDog = styled.button`
  width: 100px;
  height: 40px;
  border: none;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 10px;
  & :hover {
    color: #FFFFFF;
    background: #F6C101;
  }
`

export const MessageCreateDog = styled.p`
  font-size: 1.5rem;
  font-weight: 800;
  color: #F6C101;
  margin: 0;
`

export const BackCreateDog = styled.button`
  position: absolute;
  top: 50px;
  left: 50px;
  width: 120px;
  height: 60px;
  border: none;
  background-color: #FFFFFF;
  border-radius: 14px;
  cursor: pointer;
  & img {
    width: 40px;
    height: 40px;
  }
`
