import { ContainerCard, DetailCard, FavoriteCard, H2Card, H3Card, ImgageCard } from "./CardStyles"
import Favorite from '../../assets/paw-solid-black.svg';
import { Link } from "react-router-dom";


export const Card = ({id, name, breed, height_min, height_max, weight_min, weight_max, life_span, image, temperaments}) => {
  return (
    <ContainerCard className="ContainerCard">
      <Link to={`/home/${id}`}>
        <ImgageCard><img src={image} alt={image} /></ImgageCard>
        <H2Card>{name}</H2Card>
      </Link>
      <DetailCard>
        <span>Breed: {breed}</span>
        <span>Life Span: {life_span}</span>
        <span>Weight Min: {weight_min}</span>
        <span>Height Max: {height_max}</span>
        <span>Weight Max: {weight_max}</span>
        <span>Height Min: {height_min}</span>
      </DetailCard>
      <H3Card>{temperaments}</H3Card>
      <FavoriteCard><img src={Favorite} alt={Favorite} /></FavoriteCard>
    </ContainerCard>
  )
}
