export const Validation = (values) => {
  const errors = {};

  if (!/^[a-zA-Z]+$/.test(values.name.trim())) {
    errors.name = 'The name can only contain letters';
  }

  if (!/^[a-zA-Z]+$/.test(values.breed.trim())) {
    errors.breed = 'Race can only contain letters';
  }

  if (!/^\d+$/.test(values.height_max.trim())) {
    errors.height_max = 'The maximum height can only contain numbers';
  }

  if (!/^\d+$/.test(values.height_min.trim())) {
    errors.height_min = 'The minimum height can only contain numbers';
  }

  if (!/^\d+$/.test(values.weight_min.trim())) {
    errors.weight_min = 'The minimum weight can only contain numbers';
  }

  if (!/^\d+$/.test(values.weight_max.trim())) {
    errors.weight_max = 'The maximum weight can only contain numbers';
  }

  if (/^[\w\s]+$/.test(values.life_span.trim())) {
    errors.life_span = 'Life span cannot be empty "Example: 1 - 2 years"';
  }

  if (values.temperaments.length === 0) {
    errors.temperaments = 'You must select at least one temperament';
  }

  return errors;
}