import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Cards } from '../Cards/Cards';
import { ButtonPaginated, ContainerPaginated, PagesPaginated } from './PaginatedStyles';

export const Paginated = () => {
  const { dogs } = useSelector((state) => state); // Obetener todos los Perros del stado global
  const [currentPage, setCurrentPage] = useState(1); // Estado local para la pagina actual

  const dogsPerPage = 8; // Cantidad de perros por pagina
  const totalPages = Math.ceil(dogs.length / dogsPerPage); // Calcular el numero de paginas

  useEffect(() => {
    // Actualizar la pagina actual al cambiar el estado local
    setCurrentPage(1);
  }, [dogs]);

  const handleChangePage = (page) => {
    // Actualizar el estado local de la pagina actual
    setCurrentPage(page);
  };

  // Seleccionar solo los elementos que se deben mostrar en la pagina actual
  const startIndex = (currentPage - 1) * dogsPerPage;
  const endIndex = startIndex + dogsPerPage;
  const pageItems = dogs?.slice(startIndex, endIndex); // Muestra un array de 8 objetos
  // console.log(pageItems)

  return (
    <ContainerPaginated className="ContainerPaginated">
      <Cards items={pageItems} />
      <PagesPaginated>
        {Array.from({ length: totalPages }, (_, i) => (
          <ButtonPaginated
            key={i + 1}
            onClick={() => handleChangePage(i + 1)}
            disabled={currentPage === i + 1}
          >
            {i + 1}
          </ButtonPaginated>
        ))}
      </PagesPaginated>
    </ContainerPaginated>
  );
};
