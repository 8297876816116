import styled from 'styled-components';

export const ContainerCard = styled.div`
  display: grid;
  background: #ffffff;
  width: 400px;
  height: 500px;
  border-radius: 20px;
  grid-template-rows: 1fr;
  padding: 20px;
`;

export const ImgageCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  & img {
    border-radius: 10px;
    width: 250px;
    height: 250px;
  }
`;

export const H2Card = styled.h2`
  font-size: 26px;
  font-weight: 800;
`;

export const DetailCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  & span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const H3Card = styled.h3`
  font-size: 16px;
  font-weight: 400;
`;

export const FavoriteCard = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  & img {
    width: 20px;
    height: 20px;
  }
`;
