import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { clearDogDetail, getDogId } from '../../redux/actions/actions';
import {
  ButtonDetailDog,
  ContainerDetailDog,
  DescriptionDetailDog,
  ImageDetailDog,
} from './DetailDogStyles';
import Back from '../../assets/back-arrow.svg';

export const DetailDog = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { dogDetail } = useSelector((state) => state);

  const backToHome = () => {
    navigate('/home');
  };

  useEffect(() => {
    dispatch(getDogId(id));
    dispatch(clearDogDetail())
  }, [dispatch, id]);

  return (
    <ContainerDetailDog className="ContainerDetailDog">
      <ButtonDetailDog onClick={backToHome}>
        <img src={Back} alt={Back} />
      </ButtonDetailDog>
      <DescriptionDetailDog className="DescriptionDetailDog">
        <h1>{dogDetail.name}</h1>
        <h2>Breed: {dogDetail.breed}</h2>
        <h2>Life Span: {dogDetail.life_span}</h2>
        <h3>Height Min: {dogDetail.height_min}</h3>
        <h3>Height Max: {dogDetail.height_max}</h3>
        <h3>Weight Min: {dogDetail.weight_min}</h3>
        <h3>Weight Max: {dogDetail.weight_max}</h3>
        <h4>Temeperaments: {dogDetail.temperaments}</h4>
      </DescriptionDetailDog>

      <ImageDetailDog className="ImageDetailDog">
        <img src={dogDetail.image} alt={dogDetail.image} />
      </ImageDetailDog>
    </ContainerDetailDog>
  );
};
