export const GET_ALL_DOGS = 'GET_ALL_DOGS';
export const GET_ALL_TEMPERAMENTS = 'GET_ALL_TEMPERAMENTS';
export const GET_NAME_DOG = 'GET_NAME_DOG';
export const GET_DOG_ID = 'GET_DOG_ID';
export const FILTER_BY_NAME = 'FILTER_BY_NAME';
export const FILTER_BY_WEIGHT = 'FILTER_BY_WEIGHT';
export const FILTER_CREATE_DOG = 'FILTER_CREATE_DOG';
export const FILTER_BY_TEMPERAMENTS = 'FILTER_BY_TEMPERAMENTS';
export const CLEAR_DOG_DETAIL = 'CLEAR_DOG_DETAIL';
export const CREATE_DOG = 'CREATE_DOG';
