import styled from 'styled-components';

export const ContainerSearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #f85287;
  padding: 16px;
`;

export const LogoSearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #f4f4f4;
  width: 50px;
  height: 50px;
  & img {
    width: 30px;
    height: 30px;
  }
`;

export const FormSearchBar = styled.form`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 450px;
  height: 40px;
  & input {
    background: #ff6288;
    width: 400px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: #f4f4f4;
    font-weight: 600;
    font-size: 1rem;
  }
  & ::placeholder {
    color: #f4f4f4;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    opacity: 80%;
  }
  & button {
    border: none;
    height: 100%;
    background: #ff6288;
    border-radius: 10px;
  }
  & img {
    width: 30px;
    height: 30px;
  }
`;

export const ItemsSearchBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  width: 300px;
  height: 40px;
  & button {
    border-radius: 10px;
    background: #f4f4f4;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
  }
  & img {
    width: 30px;
    height: 30px;
  }
`;
