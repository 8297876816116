import {
  CLEAR_DOG_DETAIL,
  CREATE_DOG,
  FILTER_BY_NAME,
  FILTER_BY_TEMPERAMENTS,
  FILTER_BY_WEIGHT,
  FILTER_CREATE_DOG,
  GET_ALL_DOGS,
  GET_ALL_TEMPERAMENTS,
  GET_DOG_ID,
  GET_NAME_DOG,
} from '../actions/types';

const initialState = {
  dogs: [],
  temperaments: [],
  allDogs: [],
  dogDetail: [],
};

const rootReducer = (state = initialState, action) => {
  const { allDogs } = state;

  switch (action.type) {
    case GET_ALL_DOGS:
      return {
        ...state,
        dogs: action.payload,
        allDogs: action.payload,
      }

    case GET_ALL_TEMPERAMENTS:
      return {
        ...state,
        temperaments: action.payload
      }

    case GET_NAME_DOG:
      return {
        ...state,
        dogs: action.payload.dogSearch,
      };

    case FILTER_BY_NAME:
      const filterDogs =
        action.payload === 'A-Z'
          ? state.dogs.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              return 0;
            })
          : state.dogs.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
              return 0;
            });
      return {
        ...state,
        dogs: filterDogs,
      };

    case FILTER_BY_WEIGHT:
      const allDogsWeights = state.dogs.filter((dog) => dog.weight_min);
      const filterWeights =
        action.payload === 'min_weight'
          ? allDogsWeights.sort((a, b) => {
              return a.weight_min - b.weight_min;
            })
          : allDogsWeights
              .sort((a, b) => {
                return a.weight_min - b.weight_min;
              })
              .reverse();
      return {
        ...state,
        dogs: filterWeights,
      };

    case FILTER_CREATE_DOG:
      const filterCreated =
        action.payload === 'DB'
          ? allDogs.filter((dog) => dog.createdDatabase)
          : allDogs.filter((dog) => !dog.createdDatabase);
      return {
        ...state,
        dogs: action.payload === 'all' ? state.allDogs : filterCreated,
      };

    case FILTER_BY_TEMPERAMENTS:
      const filterTemperament =
        action.payload === 'All'
          ? allDogs
          : allDogs.filter((dog) => dog.temperaments?.includes(action.payload));
      return {
        ...state,
        dogs: filterTemperament,
      };

    case GET_DOG_ID:
      return {
        ...state,
        dogDetail: action.payload
      }

    case CLEAR_DOG_DETAIL:
      return {
        ...state,
        dogDetail: {}
      }

    case CREATE_DOG:
      return {
        ...state,
        dogs: [...state.dogs, action.payload]
      }

    default:
      return state;
  }
};

export default rootReducer;
