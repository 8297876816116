import styled from 'styled-components';

export const ContainerPaginated = styled.div``;

export const PagesPaginated = styled.div`
  display: flex;
  gap: 6px;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const ButtonPaginated = styled.button`
  background: #F6C101;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  color: #FFFFFF;
  :hover {
    background-color: #F85287;
  }
  :disabled {
    background-color: #0A1820;
  }
`;
